import type { SVGProps } from 'react'
const Ics = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 268 63" {...props}>
    <defs>
      <style>{'.cls-1{fill:#fff;}'}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <rect x={0.75} y={0.75} width={266.5} height={61.5} rx={10.52} />
        <path
          className="cls-1"
          d="M256.73,1.5a9.76,9.76,0,0,1,9.77,9.77V51.73a9.76,9.76,0,0,1-9.77,9.77H11.27A9.76,9.76,0,0,1,1.5,51.73V11.27A9.76,9.76,0,0,1,11.27,1.5H256.73m0-1.5H11.27A11.28,11.28,0,0,0,0,11.27V51.73A11.28,11.28,0,0,0,11.27,63H256.73A11.28,11.28,0,0,0,268,51.73V11.27A11.28,11.28,0,0,0,256.73,0Z"
        />
        <path
          className="cls-1"
          d="M68.61,9.67h1.2v2h-.42L63.86,26.8H62.15ZM65.5,20.16h7.81l.54,1.38H65Zm3.93-8.52v-2h.78L76.65,26.8H75Z"
        />
        <path
          className="cls-1"
          d="M79.85,26.23A5.27,5.27,0,0,1,78,24a8,8,0,0,1-.66-3.37h0A8,8,0,0,1,78,17.25,5.4,5.4,0,0,1,79.86,15a4.94,4.94,0,0,1,4.52-.45,4.33,4.33,0,0,1,1.45,1,4.42,4.42,0,0,1,1,1.49h0V9.67H88.4V26.8H86.85V24.23h-.06a4.37,4.37,0,0,1-1,1.48,4.53,4.53,0,0,1-1.43,1,4.64,4.64,0,0,1-1.81.35A4.71,4.71,0,0,1,79.85,26.23ZM84.94,25a4.2,4.2,0,0,0,1.4-1.76,6.35,6.35,0,0,0,.51-2.62h0A6.27,6.27,0,0,0,86.34,18a4.2,4.2,0,0,0-1.41-1.75,3.44,3.44,0,0,0-2.05-.63,3.61,3.61,0,0,0-2.07.61A3.94,3.94,0,0,0,79.44,18,6.69,6.69,0,0,0,79,20.62h0a6.74,6.74,0,0,0,.48,2.65A3.94,3.94,0,0,0,80.81,25a3.77,3.77,0,0,0,4.13,0Z"
        />
        <path
          className="cls-1"
          d="M93.1,26.23A5.27,5.27,0,0,1,91.27,24a8,8,0,0,1-.66-3.37h0a8,8,0,0,1,.66-3.37A5.4,5.4,0,0,1,93.11,15a4.94,4.94,0,0,1,4.52-.45,4.33,4.33,0,0,1,1.45,1,4.42,4.42,0,0,1,1,1.49h0V9.67h1.55V26.8H100.1V24.23H100a4.37,4.37,0,0,1-1,1.48,4.53,4.53,0,0,1-1.43,1,4.64,4.64,0,0,1-1.81.35A4.71,4.71,0,0,1,93.1,26.23ZM98.19,25a4.2,4.2,0,0,0,1.4-1.76,6.35,6.35,0,0,0,.51-2.62h0A6.27,6.27,0,0,0,99.59,18a4.2,4.2,0,0,0-1.41-1.75,3.44,3.44,0,0,0-2.05-.63,3.61,3.61,0,0,0-2.07.61A3.94,3.94,0,0,0,92.69,18a6.69,6.69,0,0,0-.48,2.64h0a6.74,6.74,0,0,0,.48,2.65A3.94,3.94,0,0,0,94.06,25a3.77,3.77,0,0,0,4.13,0Z"
        />
        <path
          className="cls-1"
          d="M110.86,26.28a3.27,3.27,0,0,1-.76-2.37V15.77h-1.81V14.46h1.81V11.12h1.62v3.34h2.43v1.31h-2.43v8.11a1.91,1.91,0,0,0,.43,1.43,1.84,1.84,0,0,0,1.3.4,2.42,2.42,0,0,0,.39,0l.31,0v1.27a3.59,3.59,0,0,1-.45.07,5.44,5.44,0,0,1-.57,0A3.09,3.09,0,0,1,110.86,26.28Z"
        />
        <path
          className="cls-1"
          d="M118,26.24A5.25,5.25,0,0,1,116,24a7.64,7.64,0,0,1-.71-3.4v0a7.57,7.57,0,0,1,.71-3.39A5.2,5.2,0,0,1,118,15a5.93,5.93,0,0,1,5.88,0,5.36,5.36,0,0,1,2,2.2,7.58,7.58,0,0,1,.71,3.4v0a7.61,7.61,0,0,1-.71,3.4,5.4,5.4,0,0,1-2,2.22,5.48,5.48,0,0,1-2.95.78A5.59,5.59,0,0,1,118,26.24Zm5.07-1.2a3.86,3.86,0,0,0,1.4-1.73,6.61,6.61,0,0,0,.5-2.68v0a6.62,6.62,0,0,0-.5-2.69,3.78,3.78,0,0,0-1.41-1.7,3.82,3.82,0,0,0-2.13-.59,3.7,3.7,0,0,0-2.1.6,3.89,3.89,0,0,0-1.41,1.71,6.52,6.52,0,0,0-.5,2.67v0a6.61,6.61,0,0,0,.5,2.68A4,4,0,0,0,118.82,25a4.1,4.1,0,0,0,4.24,0Z"
        />
        <path
          className="cls-1"
          d="M68.32,34.67H70.9v3H70L65.4,51.8H62.2ZM66.12,45H74l.82,2.37H65.3Zm3.95-7.29v-3h1.7L77.88,51.8h-3.2Z"
        />
        <path
          className="cls-1"
          d="M79.41,39.22h3v2.17h.07a4.58,4.58,0,0,1,.93-1.3,3.94,3.94,0,0,1,1.32-.83,4.39,4.39,0,0,1,1.64-.3,4.83,4.83,0,0,1,2.78.79A5.06,5.06,0,0,1,90.9,42a8.85,8.85,0,0,1,.64,3.5h0A8.8,8.8,0,0,1,90.9,49a5,5,0,0,1-1.79,2.25,4.72,4.72,0,0,1-2.74.79,4.78,4.78,0,0,1-1.66-.28A3.9,3.9,0,0,1,83.37,51a4.24,4.24,0,0,1-.94-1.27h-.07V56h-3Zm7.69,9.85a3.24,3.24,0,0,0,1.06-1.4,5.63,5.63,0,0,0,.37-2.15h0a5.75,5.75,0,0,0-.37-2.18,3.1,3.1,0,0,0-1.07-1.39,2.91,2.91,0,0,0-3.25,0,3.29,3.29,0,0,0-1.1,1.41,5.4,5.4,0,0,0-.39,2.15h0a5.43,5.43,0,0,0,.39,2.15,3.32,3.32,0,0,0,1.1,1.4,2.91,2.91,0,0,0,3.26,0Z"
        />
        <path
          className="cls-1"
          d="M93.45,39.22h2.94v2.17h.07a4.79,4.79,0,0,1,.93-1.3,3.94,3.94,0,0,1,1.32-.83,4.4,4.4,0,0,1,1.65-.3,4.8,4.8,0,0,1,2.77.79,5,5,0,0,1,1.8,2.26,8.68,8.68,0,0,1,.64,3.5h0a8.63,8.63,0,0,1-.64,3.49,5,5,0,0,1-1.79,2.25,5.09,5.09,0,0,1-4.39.51A4,4,0,0,1,97.4,51a4.24,4.24,0,0,1-.94-1.27h-.07V56H93.45Zm7.68,9.85a3.17,3.17,0,0,0,1.06-1.4,5.63,5.63,0,0,0,.37-2.15h0a5.75,5.75,0,0,0-.37-2.18,3,3,0,0,0-1.07-1.39,2.9,2.9,0,0,0-3.24,0,3.21,3.21,0,0,0-1.1,1.41,5.22,5.22,0,0,0-.4,2.15h0a5.26,5.26,0,0,0,.4,2.15,3.24,3.24,0,0,0,1.1,1.4,2.63,2.63,0,0,0,1.61.5A2.68,2.68,0,0,0,101.13,49.07Z"
        />
        <path className="cls-1" d="M107.56,34.67h3V51.8h-3Z" />
        <path
          className="cls-1"
          d="M115.31,51.26A5.18,5.18,0,0,1,113.23,49a7.57,7.57,0,0,1-.73-3.45h0a7.77,7.77,0,0,1,.73-3.46,5.42,5.42,0,0,1,2.06-2.3,5.76,5.76,0,0,1,3.12-.82,5.89,5.89,0,0,1,3.11.79,5.21,5.21,0,0,1,2,2.21,7.42,7.42,0,0,1,.7,3.33v1H114v-2h8.87l-1.42,1.84V45a5,5,0,0,0-.38-2.06,2.84,2.84,0,0,0-1-1.23,2.87,2.87,0,0,0-1.53-.41,2.81,2.81,0,0,0-1.56.43A2.76,2.76,0,0,0,115.82,43a4.69,4.69,0,0,0-.39,2v1.14a4.51,4.51,0,0,0,.39,2,2.86,2.86,0,0,0,1.1,1.25,3.18,3.18,0,0,0,1.7.43,3.41,3.41,0,0,0,1.29-.23,2.93,2.93,0,0,0,.92-.6,2.17,2.17,0,0,0,.51-.8l0-.08h2.75l0,.13a4.5,4.5,0,0,1-.57,1.42,4.69,4.69,0,0,1-1.12,1.25,5.27,5.27,0,0,1-1.66.89,6.71,6.71,0,0,1-2.18.32A6.34,6.34,0,0,1,115.31,51.26Z"
        />
        <path
          className="cls-1"
          d="M134.5,51A7.11,7.11,0,0,1,131.71,48a10.47,10.47,0,0,1-1-4.72h0a10.56,10.56,0,0,1,1-4.71,7.26,7.26,0,0,1,2.79-3.06,8.17,8.17,0,0,1,4.26-1.07,7.93,7.93,0,0,1,3.54.76,6.61,6.61,0,0,1,2.54,2.1,6.47,6.47,0,0,1,1.21,3.08l0,.12h-3l-.06-.22a4.28,4.28,0,0,0-.82-1.7,3.92,3.92,0,0,0-1.43-1.11,4.81,4.81,0,0,0-2-.39,4.55,4.55,0,0,0-2.6.76,5,5,0,0,0-1.71,2.15,8.23,8.23,0,0,0-.6,3.29h0a8.31,8.31,0,0,0,.6,3.3,5,5,0,0,0,1.72,2.16,4.44,4.44,0,0,0,2.59.76,4.73,4.73,0,0,0,2-.38A3.89,3.89,0,0,0,142.17,48a4.58,4.58,0,0,0,.87-1.8l0-.12h3l0,.13a6.36,6.36,0,0,1-1.2,3.09,6.56,6.56,0,0,1-2.55,2.1,8.85,8.85,0,0,1-7.8-.33Z"
        />
        <path
          className="cls-1"
          d="M149.57,51.54a3.63,3.63,0,0,1-1.48-1.34,3.76,3.76,0,0,1-.53-2v0a3.22,3.22,0,0,1,.57-1.93A3.71,3.71,0,0,1,149.76,45a7.56,7.56,0,0,1,2.59-.57l4.8-.29v2l-4.39.28a3,3,0,0,0-1.67.52,1.43,1.43,0,0,0-.58,1.2h0a1.45,1.45,0,0,0,.58,1.22,2.61,2.61,0,0,0,1.57.44,3.27,3.27,0,0,0,1.52-.34,2.69,2.69,0,0,0,1.06-.94,2.4,2.4,0,0,0,.4-1.34V43.36a1.94,1.94,0,0,0-.6-1.5,2.46,2.46,0,0,0-1.73-.55,2.91,2.91,0,0,0-1.68.44,1.85,1.85,0,0,0-.78,1.15l0,.11H148.1v-.15a3.8,3.8,0,0,1,.75-2,4.29,4.29,0,0,1,1.81-1.39,6.73,6.73,0,0,1,2.74-.51,6.57,6.57,0,0,1,2.74.52,4.21,4.21,0,0,1,1.79,1.46,3.81,3.81,0,0,1,.64,2.21V51.8h-2.94V49.87h-.08a4,4,0,0,1-.92,1.14,4.42,4.42,0,0,1-1.3.75,4.73,4.73,0,0,1-1.56.26A4.89,4.89,0,0,1,149.57,51.54Z"
        />
        <path className="cls-1" d="M161.05,34.67h3V51.8h-3Z" />
        <path
          className="cls-1"
          d="M168.8,51.26A5.2,5.2,0,0,1,166.71,49a7.7,7.7,0,0,1-.72-3.45h0a7.64,7.64,0,0,1,.73-3.46,5.4,5.4,0,0,1,2.05-2.3,6.43,6.43,0,0,1,6.23,0A5.15,5.15,0,0,1,177,42a7.42,7.42,0,0,1,.71,3.33v1H167.45v-2h8.87l-1.43,1.84V45a4.8,4.8,0,0,0-.38-2.06,2.82,2.82,0,0,0-1-1.23,2.92,2.92,0,0,0-1.53-.41,2.87,2.87,0,0,0-1.57.43A2.74,2.74,0,0,0,169.31,43a4.69,4.69,0,0,0-.39,2v1.14a4.51,4.51,0,0,0,.39,2,2.84,2.84,0,0,0,1.09,1.25,3.23,3.23,0,0,0,1.7.43,3.51,3.51,0,0,0,1.3-.23,2.93,2.93,0,0,0,.92-.6,2,2,0,0,0,.5-.8l0-.08h2.76l0,.13a5,5,0,0,1-.57,1.42,4.69,4.69,0,0,1-1.12,1.25,5.38,5.38,0,0,1-1.66.89,6.73,6.73,0,0,1-2.19.32A6.27,6.27,0,0,1,168.8,51.26Z"
        />
        <path
          className="cls-1"
          d="M179.62,39.22h2.94v2h.07a3.89,3.89,0,0,1,1.46-1.63,4.44,4.44,0,0,1,2.39-.6,4.08,4.08,0,0,1,3.21,1.26,4.93,4.93,0,0,1,1.14,3.44V51.8h-3V44.25a3.09,3.09,0,0,0-.63-2.07,2.38,2.38,0,0,0-1.89-.73,2.74,2.74,0,0,0-1.47.39,2.58,2.58,0,0,0-1,1.07,3.45,3.45,0,0,0-.35,1.59v7.3h-2.94Z"
        />
        <path
          className="cls-1"
          d="M195.11,51.26A5,5,0,0,1,193.3,49a8.81,8.81,0,0,1-.63-3.49h0a8.68,8.68,0,0,1,.64-3.5,5.09,5.09,0,0,1,1.8-2.25,4.75,4.75,0,0,1,2.74-.79,4.63,4.63,0,0,1,1.66.29,4,4,0,0,1,2.27,2.13h.07V34.67h3V51.8h-3V49.67h-.07a3.81,3.81,0,0,1-.92,1.27,3.86,3.86,0,0,1-1.33.82,4.42,4.42,0,0,1-1.66.29A4.77,4.77,0,0,1,195.11,51.26Zm5.26-2.19a3.2,3.2,0,0,0,1.09-1.41,5.43,5.43,0,0,0,.39-2.15h0a5.38,5.38,0,0,0-.39-2.14A3.35,3.35,0,0,0,200.37,42a2.72,2.72,0,0,0-1.61-.5,2.82,2.82,0,0,0-1.65.49,3.19,3.19,0,0,0-1.07,1.4,5.61,5.61,0,0,0-.37,2.16h0a5.68,5.68,0,0,0,.37,2.16,3.16,3.16,0,0,0,1.07,1.41,2.75,2.75,0,0,0,1.65.49A2.65,2.65,0,0,0,200.37,49.07Z"
        />
        <path
          className="cls-1"
          d="M208.81,51.54a3.59,3.59,0,0,1-1.49-1.34,3.76,3.76,0,0,1-.53-2v0a3.22,3.22,0,0,1,.57-1.93A3.71,3.71,0,0,1,209,45a7.62,7.62,0,0,1,2.59-.57l4.81-.29v2l-4.4.28a3,3,0,0,0-1.67.52,1.43,1.43,0,0,0-.58,1.2h0a1.43,1.43,0,0,0,.59,1.22,2.55,2.55,0,0,0,1.56.44,3.24,3.24,0,0,0,1.52-.34,2.79,2.79,0,0,0,1.07-.94,2.39,2.39,0,0,0,.39-1.34V43.36a1.94,1.94,0,0,0-.6-1.5,2.46,2.46,0,0,0-1.73-.55,2.87,2.87,0,0,0-1.67.44,1.91,1.91,0,0,0-.79,1.15l0,.11h-2.73v-.15a3.8,3.8,0,0,1,.75-2,4.35,4.35,0,0,1,1.81-1.39,6.78,6.78,0,0,1,2.75-.51,6.55,6.55,0,0,1,2.73.52,4.24,4.24,0,0,1,1.8,1.46,3.88,3.88,0,0,1,.63,2.21V51.8h-2.94V49.87h-.07a4.23,4.23,0,0,1-.93,1.14,4.29,4.29,0,0,1-1.3.75A4.73,4.73,0,0,1,211,52,4.82,4.82,0,0,1,208.81,51.54Z"
        />
        <path
          className="cls-1"
          d="M220.2,39.22h2.94v2.17h.07a3.34,3.34,0,0,1,1-1.78A2.7,2.7,0,0,1,226.1,39a4.41,4.41,0,0,1,.52,0l.42.09v2.67a2.13,2.13,0,0,0-.51-.12,5.12,5.12,0,0,0-.7-.05,3,3,0,0,0-1.44.34,2.34,2.34,0,0,0-.93,1,3.66,3.66,0,0,0-.32,1.59v7.3H220.2Z"
        />
        <path
          className="cls-1"
          d="M48.91,24.44a10.07,10.07,0,0,0-4.8,8.46,9.8,9.8,0,0,0,6,9,23.5,23.5,0,0,1-3,6.3c-1.9,2.73-3.88,5.46-6.9,5.46s-3.8-1.75-7.28-1.75-4.61,1.81-7.37,1.81-4.69-2.53-6.9-5.63a27.17,27.17,0,0,1-4.64-14.7c0-8.63,5.61-13.21,11.13-13.21,2.94,0,5.38,1.93,7.23,1.93s4.48-2,7.82-2a10.44,10.44,0,0,1,8.8,4.4Z"
        />
        <path
          className="cls-1"
          d="M38.53,16.38a9.89,9.89,0,0,0,2.35-6.18,4,4,0,0,0-.08-.89,9.89,9.89,0,0,0-6.53,3.36,9.69,9.69,0,0,0-2.45,6,3.73,3.73,0,0,0,.09.8,3.09,3.09,0,0,0,.6.06,8.6,8.6,0,0,0,6-3.17Z"
        />
      </g>
    </g>
  </svg>
)
export default Ics
